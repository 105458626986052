import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../FirebaseConfig';
import '../App.css';


function SignInWithGoogle() {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Logged In", result);
      })
      .catch((error) => {
        console.log("Error Signing In", error);
      });
  };

  return (
    <div className="flex justify-center">
      <button 
        onClick={signInWithGoogle}
        className="px-6 py-3 mt-2 text-lg font-bold text-white uppercase bg-blue-700 hover:bg-blue-800 rounded-lg lato-font" // Added lato-font class for Lato font
      >
        Sign in with Google
      </button>
    </div>
  );
}


export default function LandingPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 relative landingPageBackground" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <div className="w-full max-w-md text-center z-10 relative">
        <div className="mb-2 text-xl font-bold text-white" style={{ fontFamily: 'Roboto, sans-serif' }}>
          Welcome to Edutechnica's LMS!
        </div>
        <div className="mb-2 text-lg font text-white" style={{ fontFamily: 'Roboto, sans-serif' }}>
          Please sign in to confirm your identity!
        </div>
        <SignInWithGoogle />
        <p className="mt-5 text-xs font text-white" style={{ fontFamily: 'Roboto, sans-serif' }}>
          By signing in, you automatically become a member of Edutechnica's e-learning platform, enabling you to register for our seminars and future courses.
        </p>

      </div>
    </div>
  );
}


