import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { auth } from '../FirebaseConfig';
import PillChoice from './PillChoice';

function CourseRoom() {
    const handleSignOut = () => {
        auth.signOut();
    };
    function isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      }
    
    if(isMobileDevice()){
     return(
        <PillChoice/>
        );   
    }
    else{
        return (
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 relative landingPageBackground" style={{ fontFamily: 'Roboto, sans-serif', zIndex: 0 }}>
                <SideBar /> {/* Ensure SideBar is clickable */}
                <div className="flex-grow flex items-center justify-center" style={{ position: 'relative', zIndex: 1 }}>
                    {/* Adjusted container for a slimmer and taller aspect ratio */}
                    <div style={{ height: '71%', width: '65vh', overflow: 'auto', background: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '20px' }}>
                        <PillChoice/>
                        <SideBarIcon icon={<FaSignOutAlt size="25" color="#3B82F6" />} text='Sign Out' onClick={handleSignOut} />
                    </div>
                </div>
            </div>
        );
    }  
    
}

export default CourseRoom;

const SideBar = () => {
    const handleSignOut = () => {
        auth.signOut();
    };

    return (
        <div className="z-10 fixed top-0 left-0 h-screen w-16 m-0 flex flex-col bg-gray-900 text-white shadow-lg" style={{ zIndex: 2 }}>
            <SideBarIcon icon={<FaSignOutAlt size="25" color="#3B82F6" />} text='Sign Out' onClick={handleSignOut} />
        </div>
    );
};

const SideBarIcon = ({icon, text = 'tooltip', onClick}) => {
    return (
    <div className="sidebar-icon group" onClick={onClick}>
        {icon}
        <span className='sidebar-tooltip group-hover:scale-100'>
            {text}
        </span>
    </div>
    );
};
