import React, { useEffect } from 'react';
import './App.css';
import './index.css';
import CourseRoom from './Components/CourseRoom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './FirebaseConfig';
import LandingPage from './Components/LandingPage';

function App() {
  const [user] = useAuthState(auth);

  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        // Log the user out after 10 minutes of inactivity
        auth.signOut();
      }, 600000); // 10 minutes in milliseconds
    };

    // Setup event listeners for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);
    // Initialize the timer
    resetTimer();

    // Cleanup function to remove event listeners and clear timer
    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      clearTimeout(logoutTimer);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className='App'>
      <section>
        {user ? <CourseRoom /> : <LandingPage />}
      </section>
    </div>
  );
}

export default App;
