import React from 'react';

function PillChoice() {
  // Function to handle red pill click
  const handleRedPillClick = () => {
    window.location.href = 'https://ugm-id.zoom.us/j/93748383594?pwd=cytkcWxLUGNOekpCU01uSTV3U3NTZz09';
  };

  // Function to handle blue pill click
  const handleBluePillClick = () => {
    window.location.href = 'https://ugm-id.zoom.us/j/97454732681?pwd=Rm9iTFFtcXVrTFp5Tkp5ODhSMVVMQT09';
  };

  // Calculate aspect ratio percentage
  const aspectRatioPercent = (2245 / 1587) * 100;

  return (
    <div className="relative w-full" style={{ paddingTop: `${aspectRatioPercent}%`, background: 'transparent' }}>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-cover" style={{ backgroundImage: `url(/background-redbluepill.png)` }}>
        {/* Adjusted the flex container to move up a bit using "top" with a negative value or custom padding */}
        <div className="flex justify-center items-center h-full" style={{ position: 'relative', top: '-10px' }}>
          <button onClick={handleRedPillClick} className="mx-16">
            <img src="/red-pill.png" alt="Red Pill" className="w-24 h-auto hover:brightness-75"/>
          </button>
          <button onClick={handleBluePillClick} className="mx-16">
            <img src="/blue-pill.png" alt="Blue Pill" className="w-24 h-auto hover:brightness-75"/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PillChoice;
