// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyByxCI8CWayV9mupnAGKADmJdtVYXS6eGU",
  authDomain: "edutechnica-lms.firebaseapp.com",
  projectId: "edutechnica-lms",
  storageBucket: "edutechnica-lms.appspot.com",
  messagingSenderId: "310096123260",
  appId: "1:310096123260:web:5a66109d6c59df4c8940f9",
  measurementId: "G-NSSF728JK1"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app); // Initialize Firebase Analytics
const auth = getAuth(app); // Initialize Firebase Auth
getFirestore(app); // Initialize Firestore

export { auth }; // Export auth for use in authentication components

// Initialize Firestore
const db = getFirestore(app);

export { db };